<template>
	<page-container class="index" :page="page">
		<heading-image v-if="page.headingImage" type="large">
			<hero-image>
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p class="hero-title">
						{{ defaults[locale].homepage.headingImageTitle }}
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent" />
				</div>
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>
		</heading-image>

		<main-content>
			<div class="main-intro">
				<div class="intro-image">
					<picture>
						<source :srcset="defaults[locale].homepage.introImageWebp" type="image/webp" />
						<source :srcset="defaults[locale].homepage.introImage" />
						<img
							:src="defaults[locale].homepage.introImage"
							:alt="defaults[locale].homepage.introImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
				<div class="intro-text">
					<h2 class="subtitle">
						{{ page.subtitle }}
					</h2>
					<h1>{{ page.title || page.header }}</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</div>
		</main-content>

		<promoblocks :header="defaults[locale].homepage.promoblocksHeader" :items="promoblocksData" />

		<section v-if="defaults[locale].homepage.sectionReservationsTitle" class="section-reservations">
			<div class="row">
				<div class="columns column8">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionReservationsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.sectionReservationsTitle }}</h2>
				</div>
				<div class="columns column4 align-right">
					<div v-parse-links v-html="defaults[locale].homepage.sectionReservationsContent" />
				</div>
			</div>
		</section>

		<section v-if="defaults[locale].homepage.promotionBackgroundImage" class="section-promotion">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.promotionsSubtitle }}
					</h2>
					<h2>{{ defaults[locale].homepage.promotionsHeader }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.promotionsContent" />
				</div>
			</div>
			<picture>
				<source :srcset="defaults[locale].homepage.promotionBackgroundImageWebp" type="image/webp" />
				<source :srcset="defaults[locale].homepage.promotionBackgroundImage" />
				<img class="promotions-image" :src="defaults[locale].homepage.promotionBackgroundImage" />
			</picture>
		</section>

		<contentblocks :items="page.contentblocks" class="section-contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionContentblocksSubtitle }}
						</h2>
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
					</div>
				</div>
			</template>
		</contentblocks>

		<section class="section-clients">
			<div class="row blocks-header align-center">
				<div class="columns column12">
					<h2>{{ defaults[locale].homepage.sectionClientsTitle }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="columns column12 flex-col">
					<div v-for="item in clients" :key="item.ID" class="client-item">
						<picture>
							<source v-if="item.imageWebp" :srcset="item.image" type="image/webp" />
							<source :srcset="item.image" />
							<img :src="item.image" :alt="item.imageAlt" class="client-img" loading="lazy" />
						</picture>
					</div>
				</div>
			</div>
		</section>

		<section class="content-section section-instagram">
			<div class="row">
				<div class="columns column12 align-center">
					<h2 class="subtitle">
						<a href="https://www.instagram.com/donfoodandevents/" target="_blank">@donfoodandevents</a>
					</h2>
					<h2>{{ $t('followUsOnInstagram') }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="columns column12 align-center">
					<component
						:is="'script'"
						type="text/plain"
						data-cookiefirst-category="functional"
						src="https://snapwidget.com/js/snapwidget.js"
						async
					/>
					<iframe
						src="https://snapwidget.com/embed/1035480%22"
						class="snapwidget-widget"
						allowtransparency="true"
						frameborder="0"
						scrolling="no"
						style="border: none; overflow: hidden; width: 100%"
					/>
				</div>
			</div>
		</section>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: clients } = await useWebsiteFetch('clients', {
	query: { language: locale.value },
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 50%;
	display: flex;
	flex-flow: column nowrap;
	color: #fff;
	max-width: 1280px;
	text-align: center;
	z-index: 5;
	left: 20px;
	right: 20px;
	margin: auto;
	transform: translateY(calc(-50% + 100px));
	animation: fade-from-bottom 1.5s ease;

	.hero-title {
		width: 100%;
		font-weight: 400;
		color: #fff;
		font-size: 56px;
		margin-bottom: 10px;
		line-height: normal;
		text-transform: uppercase;
		font-family: var(--heading-font-family);
		max-width: 100%;
		letter-spacing: 1px;
	}

	:deep(> *) {
		max-width: 1000px;
		margin: 0 auto 10px;
	}

	:deep(p) {
		font-weight: 300;
		font-size: 18px;
		max-width: 740px;
	}

	:deep(a) {
		color: #fff;
		border-color: #fff;
	}

	:deep(a:hover) {
		color: var(--cta-color-hover);
	}
}

.main-intro {
	margin: auto;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
	align-items: center;
	width: 100%;

	.intro-text {
		background: var(--secondary-background-color);
		width: 60%;
		z-index: 1;
		position: relative;
		padding: 75px 75px 55px calc(10% + 75px);
		margin: 60px 0 0;
	}

	.intro-image {
		width: 50%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 60px;
		z-index: 2;
		box-shadow: 0 24px 24px rgba(0 0 0 / 15%);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.section-reservations {
	padding: 100px 0;
	background: url('~/assets/images/background-pattern-utensils.png') repeat top center
		var(--secondary-background-color);

	.row {
		background: var(--cta-color);
		padding: 60px 30px;
		color: #fff;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;

		h2 {
			color: #fff;

			&::after {
				display: none;
			}
		}

		:deep(a.button-no-fill) {
			color: #fff;
			border-color: #fff;

			&:hover {
				color: #f7f2e4;
			}
		}

		:deep(a.button) {
			background: #fff;
			color: var(--cta-color);

			&:hover {
				background: #f7f2e4;
			}
		}
	}
}

.section-instagram {
	padding: 100px 0;
	background: url('~/assets/images/background-pattern-utensils.png') repeat top center
		var(--secondary-background-color);
}

.section-clients {
	background: #fff;
	padding: 75px 0;

	.flex-col {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		margin: 20px 0 0;

		.client-item {
			width: 18%;
			margin: 10px 0;
		}
	}
}

.section-promotion {
	background: var(--secondary-background-color);
	margin: 0;
	padding: 200px 25px;
	overflow: auto;
	clip-path: inset(0);
	position: relative;

	&::after {
		background: rgb(0 0 0 / 45%);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		content: '';
	}

	.row {
		position: relative;
		z-index: 10;
		color: #fff;
		overflow: hidden;
		max-width: 800px;
	}

	.promotions-image {
		position: absolute;
		object-fit: cover;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	h2:not(.subtitle) {
		color: #fff;
	}

	:deep(.button-no-fill) {
		border-color: #fff;
	}
}

.section-contentblocks {
	.blocks-header {
		margin-bottom: 40px;
	}
}

.section-contact {
	.row:first-child {
		padding-bottom: 50px;
	}
}

@keyframes fade-from-bottom {
	0% {
		opacity: 0;
		transform: translateY(calc(-30% + 100px));
	}

	66% {
		opacity: 0;
	}

	100% {
		opacity: 1;
		transform: translateY(calc(-50% + 100px));
	}
}

@media (max-width: 1080px) {
	.hero-content {
		max-width: 650px;
	}

	.main-intro {
		flex-flow: column-reverse wrap;

		.intro-text {
			width: 100%;
			margin: 0 auto;
			padding: 60px 60px 90px;
		}

		.intro-image {
			position: relative;
			top: unset;
			left: unset;
			right: unset;
			bottom: unset;
			width: 90%;
			margin: -40px auto 0;
		}
	}
}

@media (max-width: 880px) {
	.section-promotions {
		padding: 125px 25px;
	}

	.section-reservations {
		justify-content: center;

		.columns {
			width: 100%;
			text-align: center;
		}
	}

	.promotions-image {
		position: absolute;
	}

	.hero-content {
		font-size: 15px;
		padding: 0 10px;

		.hero-title {
			font-size: 30px;
		}
	}

	.section-reservations .reservation-form > div {
		width: 100%;
	}

	.section-clients {
		.flex-col {
			justify-content: center;

			.client-item {
				width: 48%;
				margin: 20px auto;
			}
		}
	}
}
</style>
